/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The goal of this video was to create an emotional connection with potential applicants and strengthen employee loyalty. A recruiting or employer branding video presents the company's identity from the employee's perspective. This not only strengthens the image in the eyes of potential applicants, but also makes current employees feel valued and proud to be part of the team."), "\n", React.createElement(_components.p, null, "The video was shot during the company's annual team event. We created a casual and relaxed atmosphere where employees felt comfortable and acted naturally in front of the camera. This team event fostered a sense of community, which we captured in both the interview statements and the footage of togetherness to highlight the company's family-like culture."), "\n", React.createElement(_components.p, null, "A compelling employer branding video doesn't focus on the products or services, but more importantly on the people behind them. It is essential to give an authentic insight into the company and to convey the corporate philosophy in a credible and transparent way. This shows what the company stands for and what values it represents."), "\n", React.createElement(_components.p, null, "OBJECT (www.object.ch) is a renowned Swiss company that implements sophisticated software projects throughout the German-speaking world. Its prestigious customers include European XFE and the Swiss courts."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
